@import "/src/assets/styles/colors.module";
@import "/src/assets/styles/screens.module";

.studentDetailsMigration {
  width: 90%;
  margin: 0 auto;
  max-width: 580px;

  @media (max-width: $tablet) {
    width: 90%;
  }

  .studentDetailsMigration__form {
    height: 100% !important;
    margin-top: 60px;
    padding-bottom: 40px;
    & > .MuiFormControl-root {
      & > .MuiOutlinedInput-root {
        & > .MuiOutlinedInput-input {
          text-align: end;
        }
      }
    }
    & .MuiFormLabel-root {
      width: 100%;
      text-align: right;
    }
    .autoCompleteWrapper {
      margin: 0;
      margin-bottom: 30px;
    }
  }

  .button {
    margin: 10px auto 0;
  }

  .title {
    padding-top: 70px !important;
    font-size: 46px ;
  }
}

.studentDetailsMigrationSuccess {
  width: 90%;
  margin: 0 auto;
  max-width: 580px;

  @media (max-width: $tablet) {
    width: 90%;
  }
  .title {
    margin-bottom: 0px;
    font-size: 35px;
  }
}

.subTitle__success {
  font-family: "secular one";
  font-size: x-large;
  margin-bottom: 40px;
  text-align: center;
  direction: rtl;

  @media (max-width: $tablet) {
    font-size: medium;
    margin-bottom: 36px;
  }
}

.otp__form {
  height: 100% !important;
  margin-top: 60px;
  & > div:first-child {
    justify-content: space-between;
    padding-right: 80px;
    padding-left: 80px;
  }
  @media (max-width: $tablet) {
    & > div:first-child {
      justify-content: center;
      padding-right: 80px;
      padding-left: 80px;
    }
  }
}

.subTitle {
  font-family: "Open Sans";
  font-size: medium;
  text-align: center;
  direction: rtl;

  @media (max-width: $tablet) {
    font-size: small;
  }
}

.otp__formWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;

  @media (max-width: 590px) {
    margin-bottom: 15px;
  }

  .otp__form {
    .otp__inputs {
      margin: 0 auto 5%;
    }

    .otp__inputs > input {
      border-radius: 10px;
      border: solid 1px $grey7;
      background-color: #fff;
      width: 70px !important;
      height: 70px;
      font-size: 20px;
      margin: 0 5px;

      @media (max-width: 590px) {
        border: solid 1px $grey7;
        width: 50px !important;
        height: 50px;
        font-size: 15px;
      }
    }
    .otp__inputs > input:focus-visible {
      outline-color: $grey2 !important;
    }
  }
}

.migration__errorMessage {
  color: red;
  font-family: "secular one";
  text-align: center;
}

.migration__lockersGif {
  display: block;
  padding-top: 84px;
  margin: 0 auto 30px;
  width: 141px;
  object-fit: contain;

  @media (max-width: $tablet) {
    width: 98px;
    margin-bottom: 20px;
  }
}

.migration__timer {
  margin-top: 30px;
  color: black;
  font-family: "secular one";
  font-size: 18px;
  @media (max-width: $desktop) {
    font-size: 16px;
  }
}

.migration__link {
  margin-top: 30px;
  text-decoration: initial;
  color: black;
  font-family: "secular one";
  font-size: 18px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration-line: underline;

  @media (max-width: $desktop) {
    font-size: 16px;
  }
}
