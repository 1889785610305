@import "/src/assets/styles/screens.module";
@import "/src/assets/styles/colors.module";

.sideMenu {
  width: 500px !important;

  & > .MuiPaper-root.MuiPaper-elevation {
    width: 80%;
    max-width: 300px;
  }

  .sideMenu__links-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: end;
    padding: 10%;

    .sideMenu__link {
      text-decoration: none;
      color: black;
      font-family: "secular one";
      font-size: 18px;
      margin: 2% 0;

      &:hover {
        color: $torquise;
      }

      @media (max-width: $desktop) {
        font-size: 18px;
        line-height: 45px;
      }
    }
  }

  .header{
    display: flex;
    justify-content: end;
    padding: 5%;
    align-items: center;
    .header__logo-button {
      text-align: right;
      border-width: 0;
      background-color: transparent;
      cursor: pointer;
      flex-grow: 1;
      .logoImage{
        height: 30px !important;
        @media (max-width: $tablet) {
          height: 28px !important;
        }
      }
    }
    .header__close-icon{
      cursor: pointer;
      flex-grow: 1;
    }
  }

  .order__locker-wrapper{
    display: flex;
    justify-content: end;
    padding-inline-end: 10%;
    .order__locker-button{
      max-width: 200px;
      padding: 0;
      margin-bottom: 128px;
    }
  }
  
}
