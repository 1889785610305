@import "/src/assets/styles/colors.module";
@import "/src/assets/styles/screens.module";

.inputComponent {
  text-align: right;
  width: 100%;
  margin-bottom: 30px !important;

  input {
    background-color: white;
    border-radius: 10px;
  }

  fieldset {
    border-radius: 10px;
  }

  // Hide number type arrows
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield; /* Firefox */
  }
}

.inputComponent-filled {
  input {
    font-weight: bold;
  }
}

.inputComponent-error {
  fieldset {
    border: 2px solid $error !important;
  }
}
