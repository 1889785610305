@import "/src/assets/styles/screens.module";

.backButton {
  display: flex;
  align-items: center;
  
  @media (max-width: 1100px) {
    width: 100%;
  }
}

.backButton__text {
  min-width: fit-content;
  text-decoration: underline;
  font-family: "open sans";
  font-size: 14px;
  cursor: pointer;
  min-width: 95px;
}

.backButton__icon {
  min-width: 23px;
  height: 23px;
  width: 23px;
  margin-left: 5px;
  cursor: pointer;
}
