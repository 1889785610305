@import "/src/assets/styles/screens.module";
@import "/src/assets/styles/colors.module";

#header {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100px;
  box-shadow: 0px 0px 10px #0000001a;
  display: flex;
  align-items: center;
  background-color: white;
  z-index: 2;

  @media (max-width: $mobile) {
    height: 70px;
  }

  .header__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    width: 80%;

    @media (max-width: $desktop) {
      width: 93%;
    }

    & > .header__link {
      text-decoration: none;
      color: black;
      font-family: "secular one";
      font-size: 18px;
      background-color: transparent;
      border: none;
      cursor: pointer;
      text-align: left;

      @media (max-width: $desktop) {
        font-size: 16px;
      }
    }
  }

  .header__homepageLeftSide {
    display: flex;

    .header__burger {
      padding-right: 10%;

      @media (min-width: 768.1px) {
        display: none;
      }
    }
  }

  .header__orderLockerButton {
    color: white;
    background: #358fb0 0% 0% no-repeat padding-box;
    border: none;
    @media (max-width: $mobile) {
      min-width: 100px;
      height: 38px;
      font-size: 12px;
      border-radius: 12px;
    }
    @media (max-width: 240px) {
      min-width: 90px;
    }
  }

  .header__links {
    flex: 1;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: center;
    max-width: 700px;

    @media (max-width: $tablet) {
      display: none;
    }

    @media (max-width: $desktop) {
      align-items: flex-end;
    }

    .header__link {
      text-decoration: none;
      color: black;
      font-family: "secular one";
      font-size: 18px;
      margin: 0 4%;

      &:hover {
        color: $torquise;
      }

      @media (max-width: $desktop) {
        font-size: 16px;
      }
    }
  }

  .header__logo-button {
    border-width: 0;
    background-color: transparent;
    cursor: pointer;
    @media (max-width: $tablet) {
      text-align: right;
    }
    .logoImage{
      height: 52px !important;
      @media (max-width: $tablet) {
        width: 70% !important;
        height: unset !important;
      }
    }
  }

  .narrow-width {
    width: 45% !important;
  }
}
