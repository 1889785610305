@import url("https://fonts.googleapis.com/css2?family=Secular+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&family=Secular+One&display=swap");
@import "/src/assets/styles/colors.module";
@import "/src/assets/styles/screens.module";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#root {
  background-color: $grey4;
  margin-top: 100px;
}

#root,
.App {

  @media (max-width: $mobile) {
    margin-top: 70px;
  }
}

.MuiInputLabel-root {
  width: 100%;
  text-align: right;
  transform-origin: top right !important;
  font-size: 19px !important;
  direction: rtl;
  color: $grey1 !important;
  font: normal normal bold 14px/26px "open sans" !important;
}

.MuiInputLabel-root.MuiFormLabel-filled.MuiInputLabel-standard,
.MuiInputLabel-root.Mui-focused.MuiInputLabel-standard {
  transform: translate(0, 0) scale(0.9) !important;
  transform-origin: top right !important;
}

.MuiInputLabel-root.MuiFormLabel-filled.MuiInputLabel-outlined,
.MuiInputLabel-root.Mui-focused.MuiInputLabel-outlined,
.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink {
  transform: translate(0px, -27px) scale(0.9) !important;
  transform-origin: top right !important;
  padding-right: 6px;
}

.MuiOutlinedInput-notchedOutline {
  text-align: initial !important;
  border-color: $grey7 !important;

  span {
    display: none !important;
  }
}

.MuiFormHelperText-root {
  text-align: right !important;
}