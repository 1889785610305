@import "/src/assets/styles/screens.module";
@import "/src/assets/styles/fonts.module";

.pageTitle {
  font-family: map-get($font-families, SecularOne_OpenSans);
  font-size: map-get($font-sizes, 48px);
  line-height: map-get($line-heights, 68);
  font-weight: map-get($font-weights, Regular);
  color: map-get($theme-colors, black);

  margin-bottom: 29px;
  margin-top: 26px;
  text-align: center;
  direction: rtl;

  @media (max-width: $tablet) {
    font-size: map-get($font-sizes, 24px);
    margin-bottom: 36px;
    margin-right: 10%;
    margin-left: 10%;
    line-height: map-get($line-heights, 30);
  }

  .subTitle{
    font-family: map-get($font-families, OpenSans);
    font-size: map-get($font-sizes, 16px);
    line-height: map-get($line-heights, 30);
    font-weight: map-get($font-weights, Regular);
    color: map-get($theme-colors, black);
  }
}
