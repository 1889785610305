@import "/src/assets/styles/colors.module";
@import "/src/assets/styles/screens.module";
@import "/src/assets/styles/fonts.module.scss";

.faq {
  width: 100%;
  max-width: 690px;
  min-height: inherit;
  background-color: $grey4;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 auto;
  padding: 80px 4% 10%;

  @media (max-width: $tablet) {
    padding: 8% 10% 10%;
  }

  & *:not(.faq__title) {
    white-space: pre-line;
    font-family: map-get($font-families, OpenSans);
    // font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue",
    //   Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji",
    //   "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  }

  & div {
    color: $black1;
  }

  .faq__title {
    margin-bottom: 10px;
  }

  .faq__subtitle {
    font-size: 1rem;
    text-align: center;
    margin-bottom: 32px;
    font-family: "open sans", system-ui;
  }
}
