@import "/src/assets/styles/colors.module";
@import "/src/assets/styles/screens.module";
@import "/src/assets/styles/fonts.module.scss";

.scroller {
  &::-webkit-scrollbar {
    width: 15px;
    height: 15px;
  }

  &::-webkit-scrollbar-track-piece {
    background-color: #C2D2E4;
  }

  &::-webkit-scrollbar-thumb:vertical {
    height: 30px;
    background-color: #0A4C95;
  }
}

.autoCompleteWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-top: 50px;

  .MuiInputLabel-outlined.Mui-disabled {
    opacity: 0.5;
  }
}

.autoComplete {
  & .MuiInputBase-root {
    padding-right: 0 !important;
    padding-left: 30px;
    background-color: white;
    border-radius: 10px;
    font-size: map-get($font-sizes, 14px);
    font-family: map-get($font-families, OpenSans);

    &.Mui-disabled {
      opacity: 0.6;
    }
  }

  & .MuiInputBase-root.MuiInputBase-adornedStart {
    padding-left: 56px;
  }

  & .MuiChip-deleteIcon {
    margin: 0 !important;
    margin-left: 5px !important;
  }

  & .MuiAutocomplete-endAdornment {
    right: initial !important;
    left: 19px;
    display: flex;
  }
}

.MuiAutocomplete-popper {
  .MuiAutocomplete-listbox{
    direction: rtl;
    &::-webkit-scrollbar {
      width: 3px;
      height: 15px;
    }
  
    &::-webkit-scrollbar-track-piece {
      background-color: #D9D9D9;
      margin-top: 40px;
      margin-bottom: 40px;
    }
  
    &::-webkit-scrollbar-thumb:vertical {
      height: 30px;
      background-color: #656565;
    }
    max-height: 247px;
    margin-inline-end: 15px;
  }
  & > div {
    display: flex;
    flex-direction: row-reverse;
    border-radius: 18px;

    ul{
      padding: 15px;

      li{
        font-size: 14px;
        font-weight: 600;
        cursor: pointer;
        font-family: map-get($font-families, OpenSans);
      }

      li:not(:last-child) {
        margin-bottom: 10px;
      }
    }

    & > * {
      width: 100%;

      & > * {
        display: flex;
        padding-right: 5px !important;
      }
    }
  }


}

.autoComplete__textField-wrapper {
  position: relative;
}

.autoComplete__inputLabel-hyper-link{
  display: flex !important;
  justify-content: end;
  padding-left: 6px !important;
  top: -2px;
  text-decoration: underline;
  cursor: pointer;
  float: left;
  width: 10rem !important;
}

.autoComplete__textField {
  @media (max-width: $tablet) {

  }
  div > input {
    @media (max-width: $tablet) {
      font-size: 14px;
    }
    text-align: right !important;
    padding-inline-start: 21px !important;
    width: unset !important;
  }

  label {
    padding-right: 6px !important;
    top: -2px;
    right: 0;
    width: fit-content;
  }
}

.autoComplete__textField-error {
  div > fieldset {
    border-color: $error !important;
    border-width: 2px;
  }
}

.autoComplete__textField-filled {
  div > input {
    font-weight: bold;
  }
}

.autoComplete__textField-additionalLabel {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  direction: rtl;
  font-family: 'open sans';
  font-size: 13px;
}
