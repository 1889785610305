@import "/src/assets/styles/screens.module";

.wizard {
  width: 81.5%;
  margin: 0 auto;
  padding-top: 45px;
  padding-bottom: 16%;
  position: relative;
  overflow: hidden;
  min-height: inherit;

  @media (max-width: $mobile) {
    // padding-bottom: 50%;
  }

  @media (max-width: 1100px) {
    width: 100%;
  }

  @media (max-width: 1100px) {
    padding-top: 18px;
  }

  .wizard__header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    direction: ltr;

    @media (max-width: 1100px) {
      flex-direction: column-reverse;
      justify-content: flex-start;
      align-items: center;
    }

    .backButton {
      flex: 1;
      justify-content: flex-end;

      @media (max-width: 1100px) {
        flex: initial;
        justify-content: flex-end;
        margin-bottom: 40px;
      }
    }

    .stepper__wrapper {
      flex: 1;
      min-width: 600px;
      width: 30%;

      @media (max-width: 1100px) {
        min-width: initial;
        width: 78%;
        flex: initial;
      }
    }
  }

  .wizard__header-empty {
    flex: 1;

    @media (max-width: 1100px) {
      display: none;
    }
  }

  .wizrd__content {
    min-width: 600px;
    width: 100%;

    @media (max-width: 1100px) {
      min-width: initial;
      flex: initial;
    }
  }
}

.wizard__empty {
  min-height: inherit;
}
