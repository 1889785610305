@import "/src/assets/styles/screens.module.scss";

.logo {
  height: 48px;
  object-fit: contain;
  justify-content: flex-start;
  width: 125px !important;

  @media (max-width: $mobile) {
    width: 100px !important;
  }

  @media (max-width: 240px) {
    width: 70px !important;
  }
}
