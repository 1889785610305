@import "/src/assets/styles/colors.module";
@import "/src/assets/styles/screens.module";

.button {
  border-radius: 12px;
  height: 54px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "open sans";
  font-weight: bold;
  min-width: 180px;
  cursor: pointer;

  &.torquise {
    color: white;
    background: $torquise 0% 0% no-repeat padding-box;
    border: none;
  }
  &.white {
    border: 2px solid $torquise;
    letter-spacing: 0px;
    color: $torquise;
    background-color: white;
  }

  &.button__disabled {
    background-color: $grey2;
    cursor: default;
  }
}
