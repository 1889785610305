$error: #db1900;
$torquise: #358fb0;
$torquise2: #398FB0;
$yellow: #ffaa2f;
$grey1: #52555f;
$grey2: #c3c9db;
$grey3: #e6e9f2;
$grey4: #f5f6fc;
$grey5: #dfe3ef;
$grey6: #757B88;
$grey7: #D2D7E8;
$black: #000000;
$black1: #212529;
$black2: #0033A51A;