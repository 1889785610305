@import "/src/assets/styles/fonts.module.scss";


.dialogComponent {
  .MuiDialog-paper {
    width: 507px;
    height: 354px;
    border-radius: 18px;
  }
}

.MuiDialogContent-root{
  text-align: center;
  padding: 0px !important;
  margin-right: 18%;
  margin-left: 18%;

  .titleImageWrapper{
    
  }

  .titleWrapper{
    h3{
      font-family: map-get($font-families, OpenSans) !important;
      font-size: map-get($font-sizes, 24px) !important;
      font-weight: map-get($font-weights, Semibold) !important;
      color: map-get($theme-colors, black) !important;
      padding-top: 3%;
    }
    
  }

  .subTitleWrapper{
    p{
      padding-top: 4%;
      font-family: map-get($font-families, OpenSans) !important;
      font-size: map-get($font-sizes, 16px) !important;
      font-weight: map-get($font-weights, Regular) !important;
      color: map-get($theme-colors, black) !important;
    }
  }
}

.MuiDialogActions-root{
  justify-content: center !important;
  padding-bottom: 3%;
  margin-bottom: 3%;
}

.dialogActions{
  margin-bottom: 9.5%;
}

